<template>
  <div class="do-remark-layout">
    <Breadcrumb>
      <BreadcrumbItem>考试管理</BreadcrumbItem>
      <BreadcrumbItem>批阅</BreadcrumbItem>
      <BreadcrumbItem>答卷详情</BreadcrumbItem>
    </Breadcrumb>
    <div class="wrapper">
      <div class="navigation">
        <div
          @click="selectQuestion(index, answer.questionId)"
          v-for="(answer, index) in userAnswers"
          class="answer-record-item"
          :class="{ selected: currentUserIndex === index }"
          :key="`${answer.questionId}_${answer.studentId}`"
        >
          <span class="left">{{ answer.studentName }}</span>
          <span class="right">
            <JYIcon v-if="answer.userScore" style="color: green" href="#icon-gouxuan" />
            {{ answer.sectionName }} - {{ answer.questionOrder }}
          </span>
        </div>
      </div>
      <div class="remark-panel">
        <div v-if="currentQuestion && currentStudentAnswer" class="content-wrapper">
          <div class="header">
            <div class="left">
              批阅状态：
              <Tag :color="currentStudentAnswer.status === 4 ? 'default' : 'success'">{{ _getStatusByValue(currentStudentAnswer.status).label }}</Tag>
            </div>
            <div class="right">
              <span style="margin-right: 20px">
                该题总分：
                <span class="emphasize">{{ currentStudentAnswer.score }}</span>
              </span>
              <span v-if="currentStudentAnswer.status === 4 || reEditRemark">
                <InputNumber :min="0" :max="currentStudentAnswer.score" v-model="userScore" />
                <Button :loading="settingScore" style="margin-left: 10px" type="primary" size="large" @click="remarkQuestion">设置得分</Button>
              </span>
              <span v-else>
                考生该题得分:
                <span class="emphasize" style="color: orangered">{{ currentStudentAnswer.userScore }}</span>
                <span style="margin-left: 5px; cursor: pointer" @click="handleEditRemark">[重新编辑]</span>
              </span>
            </div>
          </div>
          <BaseQuestion style="margin-top: 20px" :question="currentQuestion" />
          <div v-if="currentStudentAnswer" class="user-answer">
            <span>考生答案：</span>
            <span style="white-space: pre-line" v-html="JSON.parse(currentStudentAnswer.answer)"></span>
          </div>

          <div class="ref-answer">
            <span>参考答案：</span>
            <span v-html="currentQuestion.answer"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExamAPI from '@api/exam'
import JYIcon from '@components/common/JYIcon'
import BaseQuestion from '@components/common/questions/BaseQuestion'
import { getStatusByValue } from '@/util/questionAnswerStatus'
export default {
  name: 'DoRemark',
  components: {
    BaseQuestion,
    JYIcon
  },
  created() {
    this.requestRemarkList()
  },
  data() {
    return {
      fullQuestions: [],
      userAnswers: [],
      currentQuestionId: '',
      currentUserIndex: -1,
      userScore: 0,
      settingScore: false,
      reEditRemark: false
    }
  },
  methods: {
    async requestRemarkList() {
      const examId = this.$route.params['examId']
      // const examId = 388
      const { res } = await ExamAPI.getRemarkDetailList(examId)
      this.fullQuestions = res.fullQuestions
      this.userAnswers = res.needToCorrectedAPQuestions
      if (res.fullQuestions.length > 0) {
        const questionId = this.$route.query['questionId']
        const studentIndex = res.needToCorrectedAPQuestions.findIndex(user => {
          return user.questionId === questionId
        })
        if (studentIndex !== -1) {
          this.currentUserIndex = studentIndex
          this.currentQuestionId = questionId
        } else {
          this.currentUserIndex = 0
          this.currentQuestionId = res.fullQuestions[0].id
        }
      }
    },
    async remarkQuestion() {
      this.settingScore = true
      const { answerPaperId, answerPaperQuestionId } = this.currentStudentAnswer
      try {
        await ExamAPI.remarkQuestion(answerPaperId, answerPaperQuestionId, this.userScore)
        this.userScore = 0
        await this.requestRemarkList()
      } finally {
        this.settingScore = false
        this.reEditRemark = false
      }
    },
    handleEditRemark() {
      this.reEditRemark = true
      this.userScore = this.currentStudentAnswer.userScore
    },
    selectQuestion(userIndex, questionId) {
      this.userScore = 0
      this.reEditRemark = false
      this.currentUserIndex = userIndex
      this.currentQuestionId = questionId
    },
    _getStatusByValue(status) {
      return getStatusByValue(status)
    }
  },
  computed: {
    currentQuestion() {
      return this.fullQuestions.find(question => question.id === this.currentQuestionId)
    },
    currentStudentAnswer() {
      return this.userAnswers[this.currentUserIndex]
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/mixin';
.do-remark-layout {
  text-align: left;
  padding: 20px;
}
.wrapper {
  display: flex;
  margin-top: 20px;
  .navigation,
  .remark-panel {
    .--mixin-shadow;
    background: white;
  }
}
.navigation {
  width: 300px;
  padding: 20px;
}
.remark-panel {
  margin-left: 10px;
  flex: 1;
  padding: 20px;
}
.content-wrapper {
  & > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > .right {
      display: flex;
      align-items: center;
    }
  }
}
.user-answer {
  color: #3c88d3;
  margin-top: 38px;
}
.ref-answer {
  margin-top: 38px;
}
.answer-record-item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
  border-radius: 3px;
  &:hover {
    background: #dadcde;
  }
  &.selected {
    background: #dadcde;
  }
}
.emphasize {
  font-weight: 900;
  font-size: 20px;
}
</style>
